import { QueryClient } from "react-query"
import type {
  BannerApiType,
  ProductCatalogType,
} from "../../contracts/contracts"
import { fetchBanners } from "@/api/bannersAPI"
import { fetchCatalog, fetchHits } from "@/api/catalogAPI"
import { Homepage, HomepagePropsType } from "@/components/Homepage"
import { Meta } from "@/components/Meta/Meta"
import { setBanners, setInitialHits, setInitialNews, setNews } from "@/store/reducers/appSlice"
import { wrapper } from "@/store/store"
import type { CatalogResponseType } from "@/types"

export const getServerSideProps = wrapper.getServerSideProps(
  (store) =>
    async (): Promise<{
      props: HomepagePropsType
    }> => {
      const queryClient = new QueryClient()

      const banners: BannerApiType[] | null = await queryClient.fetchQuery(
        ["banners"],
        () =>
          fetchBanners({
            server: true,
          }),
      )
      store.dispatch(setBanners(banners || []))

      const hits: ProductCatalogType[] | null = await queryClient.fetchQuery(
        ["hits"],
        () =>
          fetchHits({
            server: true,
          }),
      )

      store.dispatch(setInitialHits(hits || []))

      const newProducts: CatalogResponseType | null =
        await queryClient.fetchQuery(["new"], () =>
          fetchCatalog({
            isEnabled: "1",
            page: 1,
            perPage: 20,
            isNew: true,
            server: true,
          }),
        )

      store.dispatch(setInitialNews(newProducts?.products || []))

      return {
        props: {},
      }
    },
)

export default function Home(): JSX.Element {
  return (
    <>
      <Meta />
      <Homepage />
    </>
  )
}
