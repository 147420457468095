import { FC } from "react";
import dynamic from "next/dynamic";
import LazyHydrate from "react-lazy-hydration";
import type { BannerApiType, ProductCatalogType } from "../../../contracts/contracts";
import { SliderBanners } from "../Banners/Slider";
import { MainPageRecommends } from "../LeadHit";
import { lazyHydrationConfig } from "@/hooks/lazyHydrate";
import { useAppSelector } from "@/hooks/redux";
import { cssHiddenMD, cssSectionUnderHeader } from "@/styles/utils/Utils";
import type { CatalogResponseType } from "@/types";
const SingleHome = dynamic(() => import("../Banners/SingleHome").then(m => m.SingleHome));
const PersonalRecommendations = dynamic(() => import("./PersonalRecommendations").then(m => m.PersonalRecommendations));
const BusinessAreasBanners = dynamic(() => import("../../components/BusinessAreasBanners").then(m => m.BusinessAreasBanners));
const BrandBasis = dynamic(() => import("../../components/BrandBasis").then(m => m.BrandBasis));
const Hits = dynamic(() => import("./Hits").then(mod => mod.Hits));
const New = dynamic(() => import("./New").then(mod => mod.New));
export type SSRPropsType = {
  banners?: BannerApiType[];
  hits?: ProductCatalogType[];
  new?: CatalogResponseType | null;
};
export type HomepagePropsType = SSRPropsType;
export const Homepage: FC = () => {
  const banners = useAppSelector(({
    app
  }) => app.banners);
  const bannersSlider = banners !== null ? banners.main_slider : undefined;
  const bannerSingle = banners !== null ? banners.main_single[0] : undefined;
  return <>
      <LazyHydrate whenIdle>
        <SliderBanners banners={bannersSlider} className={cssSectionUnderHeader} />
      </LazyHydrate>
      <LazyHydrate whenVisible={lazyHydrationConfig}>
        <Hits />
      </LazyHydrate>
      <LazyHydrate whenVisible={lazyHydrationConfig}>
        <New />
      </LazyHydrate>
      <LazyHydrate whenVisible={lazyHydrationConfig}>
        <BusinessAreasBanners className={cssHiddenMD} />
      </LazyHydrate>
      <LazyHydrate whenVisible={lazyHydrationConfig}>
        <PersonalRecommendations />
      </LazyHydrate>
      <LazyHydrate whenVisible={lazyHydrationConfig}>
        <SingleHome banner={bannerSingle} />
      </LazyHydrate>

      <MainPageRecommends />

      <LazyHydrate whenVisible={lazyHydrationConfig}>
        <BrandBasis />
      </LazyHydrate>
    </>;
};