import type { FC } from "react"
import { memo, useMemo } from "react"
import Head from "next/head"
import { useRouter } from "next/router"
import { DESCRIPTION_DEFAULT, TITLE_DEFAULT } from "./constants"
import { SITE_URL, TITLE_SITE_RU } from "@/utils/constants"

export type MetaEntityType = {
  content?: string
  isVisible?: boolean
}

export type MetaPropsType = {
  title?: string
  description?: string | MetaEntityType
  ogTags?: {
    description?: string
    title?: string
    url?: string
    image?: string
  }
}

const Meta: FC<MetaPropsType> = memo(
  ({ title = TITLE_DEFAULT, description, ogTags, children }) => {
    const ogImage = useMemo(
      () => ogTags?.image || `${SITE_URL}/favicon-32x32.png`,
      [ogTags?.image],
    )

    const ogUrl = useMemo(() => ogTags?.url || SITE_URL, [ogTags?.url])

    const {
      isVisible: descrIsVisible,
      content: descrContent,
    }: Required<MetaEntityType> = useMemo(() => {
      if (!description) {
        return {
          content: DESCRIPTION_DEFAULT,
          isVisible: true,
        }
      }

      if (typeof description === "string") {
        return {
          content: description,
          isVisible: true,
        }
      }

      return {
        content: description.content || DESCRIPTION_DEFAULT,
        isVisible: !!description.isVisible,
      }
    }, [description])

    const router = useRouter()
    const canonicalUrl = `${SITE_URL}${
      router.asPath === "/" ? "" : router.asPath
    }`.split("?")[0]

    return (
      <Head>
        <title>{title}</title>
        {descrIsVisible && <meta name="description" content={descrContent} />}
        <meta property="og:type" content={"website"} />
        <meta property="og:site_name" content={TITLE_SITE_RU} />
        <meta
          property="og:description"
          content={ogTags?.description || descrContent}
        />
        <meta property="og:title" content={ogTags?.title || title} />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:image" content={ogImage} />
        <link rel="canonical" href={canonicalUrl} />
        {children}
      </Head>
    )
  },
)

Meta.displayName = "Meta"

export { Meta }
