import React, { createElement, FC, memo, useState } from "react"
import { useRouter } from "next/router"
import { MODAL_CONFIG } from "../constants"
import { BannerRoutePropsType } from "../types"
import { Button } from "@/ui/Button/Button"
import { Link } from "@/ui/Link"
import { Modal } from "@/ui/Modal/Modal"

const BannerRoute: FC<BannerRoutePropsType> = memo(
  ({ path, isModal = false, children }) => {
    const [isShowModal, setIsShowModal] = useState(false)
    const router = useRouter()

    if (!path || router.asPath === path) {
      return <>{children}</>
    }

    if (isModal) {
      return (
        <>
          <Button
            variant={"link"}
            onClick={() => {
              setIsShowModal(true)
            }}
          >
            {children}
          </Button>
          {isModal && !!path && !!MODAL_CONFIG[path] && (
            <Modal
              title={MODAL_CONFIG[path].title}
              variant={MODAL_CONFIG[path].variant}
              closeMode={"destroy"}
              isShowModal={isShowModal}
              onClose={() => {
                setIsShowModal(false)
              }}
            >
              {createElement(MODAL_CONFIG[path].Component())}
            </Modal>
          )}
        </>
      )
    }

    return (
      <Link
        href={isModal ? "" : path}
        draggable={false}
        scroll
        target={"_blank"}
      >
        {children}
      </Link>
    )
  },
)

BannerRoute.displayName = "BannerRoute"

export { BannerRoute }
