import type { FC } from "react"

export const MainPageRecommends: FC = () => {
  return <div className="lh-recs-main-page"></div>
}

export const CartPageRecommends: FC = () => {
  return <div className="lh-recs-cart-page"></div>
}

export const HistoryOrdersPageRecommends: FC = () => {
  return <div className="lh-recs-history-orders-page"></div>
}

export const NotFoundPageRecommends: FC = () => {
  return <div className="lh-recs-not-found-page"></div>
}

export const DetailProductPageRecommends: FC = () => {
  return <div className="lh-recs-detail-product-page"></div>
}
