import { FC, memo } from "react";
import Image from "next/image";
import { ViewModeBanner } from "../constants";
import { BannerPropsType } from "../types";
import { cssDesktop, cssMobile, cssTablet, StyledImage } from "./StyledBanner";
import { getImagePath } from "@/utils/helpers";
const BannerImage: FC<BannerPropsType & {
  view: ViewModeBanner;
}> = memo(({
  image: {
    tablet,
    mobile,
    desktop
  },
  view,
  priority,
  quality,
  unoptimized,
  loading,
  withFormatUrl = true
}) => {
  const objPositionDefault = desktop.objectPosition || "center";
  const objFitDefault = desktop.objectFit || "contain";
  const layoutDefault = desktop.layout || "fill";
  const widthDefault = desktop.width;
  const heightDefault = desktop.height;
  return <>
        {view === ViewModeBanner.DESKTOP && <StyledImage className={cssDesktop}>
            <Image alt={""} src={withFormatUrl ? getImagePath(desktop.src.toString()) : desktop.src.toString()} objectPosition={objPositionDefault} objectFit={objFitDefault} layout={layoutDefault} width={widthDefault} height={heightDefault} priority={priority} quality={quality} unoptimized={unoptimized} loading={loading} />
          </StyledImage>}

        {view === ViewModeBanner.TABLET && <StyledImage className={cssTablet}>
            <Image alt={""} src={withFormatUrl ? getImagePath((tablet?.src || desktop.src).toString()) : (tablet?.src || desktop.src).toString()} objectPosition={tablet?.objectPosition || objPositionDefault} objectFit={tablet?.objectFit || objFitDefault} width={tablet?.width || widthDefault} height={tablet?.height || heightDefault} priority={priority} quality={quality} layout={tablet?.layout || layoutDefault} unoptimized={unoptimized} loading={loading} />
          </StyledImage>}

        {view === ViewModeBanner.MOBILE && <StyledImage className={cssMobile}>
            <Image alt={""} src={withFormatUrl ? getImagePath((mobile?.src || desktop.src).toString()) : (mobile?.src || desktop.src).toString()} objectPosition={mobile?.objectPosition || objPositionDefault} objectFit={mobile?.objectFit || objFitDefault} width={mobile?.width || widthDefault} height={mobile?.height || heightDefault} priority={priority} layout={mobile?.layout || layoutDefault} quality={quality} unoptimized={unoptimized} loading={loading} />
          </StyledImage>}
      </>;
});
BannerImage.displayName = "BannerImage";
export { BannerImage };